@import "@ngxhq/theme-ngxhq/scss/_core";
@import '@angular/material/prebuilt-themes/indigo-pink.css';
@import 'ngx-toastr/toastr';

@import '../assets/appicons/appicons';
@import './vendor';

p-calendar .p-inputtext[readonly] {
    border-color: #036;
    color: #036 !important;
    cursor: default;
    opacity: 1;
}

p-calendar.ng-invalid.ng-touched .p-inputtext[readonly] {
    border-color: #e60c0c !important;
}

.p-datepicker table td .p-disabled {
    opacity: 0.5 !important;
}

.p-datatable-resizable .p-datatable-tbody>tr>td,
.p-datatable-resizable .p-datatable-tfoot>tr>td,
.p-datatable-resizable .p-datatable-thead>tr>th {
    white-space: normal !important;
}

.p-datatable table {
    table-layout: auto !important;
}

.p-message-warning {
    padding: .5rem;
    font-size: 80%;
    color: #652500;
    background-color: #ffecb3;
    max-width: 20rem;

    .pi {
        font-size: 80%;
        margin-right: .5rem;
    }
}

.p-dialog .p-dialog-footer {
    justify-content: flex-end !important;
}

.p-confirm-dialog-reject {
    background-color: transparent !important;
    border: transparent !important;
    color: #036 !important;
}
